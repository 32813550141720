<template>
  <div>
    <v-container>
      <v-card>
        <v-card-title class="d-flex align-center">
          <v-row>
            <v-col cols="6">
              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                icon
                single-line
                hide-details
              ></v-text-field>
            </v-col>

            <v-col cols="6" class="d-flex justify-end">
              <v-icon
                color="primary"
                @click="loadData"
                v-datav-text="'mdi-refresh'"
              />
              <v-icon color="primary" @click="addItem" v-text="'mdi-plus'" />
            </v-col>
          </v-row>
        </v-card-title>
        <v-data-table
          :headers="headers"
          :items="teams"
          :search="search"
          :loading="loading"
          :items-per-page="-1"
          sort-by="category"
        >
          <template v-slot:[`item.actions`]="{ item }">
            <div class="d-flex align-center">
              <v-icon color="green" small @click="editItem(item)"
                >mdi-pencil</v-icon
              >
              <v-icon color="error" small @click="deleteItem(item)"
                >mdi-delete</v-icon
              >
              <v-btn icon @click="openUser(item)">
                <v-icon small> mdi-login</v-icon></v-btn
              >
            </div>
          </template>
        </v-data-table>
      </v-card>
    </v-container>
    <users-dialog v-model="dialog" v-if="dialog" :userProp="currentUser" />
  </div>
</template>

<script>
import UsersDialog from '@/components/UsersDialog.vue'
import axios from '../services/myAxios.js'
export default {
  name: 'users-crud',
  components: { UsersDialog },
  data() {
    return {
      currentUser: {},
      search: '',
      loading: false,
      dialog: false,
      headers: [
        { text: this.$t('userManagement.name'), value: 'name' },
        {
          text: this.$t('generics.actions'),
          value: 'actions',
          class: 'd-flex justify-end text-end',
          cellClass: 'd-flex justify-end text-end',
        },
      ],
    }
  },
  computed: {
    teams() {
      return this.$store.getters.teamsCrud
    },
  },
  methods: {
    async openUser({ _id }) {
      console.log('openUser', _id)
      const { data: token } = await axios.get(`/auth/token/${_id}`)
      window.open(process.env.VUE_APP_CLIENT_URL + '/?token=' + token, '_blank')
    },
    loadData() {
      this.$store.dispatch('team/index')
    },
    addItem() {
      // Here you can add a new item to the list
      this.dialog = true
    },
    editItem(item) {
      this.currentUser = item
      this.dialog = true
      // Here you can edit an existing item
    },
    async deleteItem({ _id }) {
      let { isConfirmed } = await this.$createSwal({
        title: 'האם אתה בטוח שאתה רוצה למחוק? מחיקה היא פעולה בלתי הפיכה',
        confirmButtonText: this.$t('generics.erase'),
      })
      if (!isConfirmed) return
      this.$store.dispatch('team/destroy', _id)
    },
  },
  mounted() {
    this.loadData()
  },
  watch: {
    dialog(newValue, oldValue) {
      if (!newValue && !!oldValue) this.currentUser = {}
    },
  },
}
</script>
