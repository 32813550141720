export const categories = [
  { color: 'purple lighten-2', text: 'לוגיסטיקה', category: 'logistics' },
  { color: 'blue lighten-2', text: 'רפואה', category: 'medic' },
  { color: 'lime lighten-2', text: 'דוברות ומידע', category: 'pr' },
  { color: 'light-green lighten-2', text: 'ביטחון', category: 'security' },
  { color: 'amber lighten-3', text: 'רווחה', category: 'social' },
  { color: 'blue-grey lighten-2', text: 'חינוך', category: 'educational' },
  { color: 'deep-orange lighten-3', text: 'מטה', category: 'headQuaters' },
]
export const categoriesMap = categories.reduce((acc, curr) => {
  acc[curr.category] = curr
  return acc
}, {})
export const eventType = [
  { text: 'אירוע לוגיסטי', additional: 'פגעי מזג אוויר / שריפה', name: 'fire' },
  {
    text: 'אירוע לוגיסטי קולוסלי',
    additional: 'רעידת אדמה',
    name: 'earthQuake',
  },
  {
    text: 'אירוע בטחוני ביישוב',
    additional: 'חדירה',
    name: 'intruder',
  },
  {
    text: 'אירוע אזרחי ביישוב',
    additional: 'תאונת דרכים',
    name: 'carAccident',
  },
  {
    text: 'אירוע מחוץ לישוב',
    additional: 'תאונת דרכים' + ' / ' + 'פיגוע מחוץ לישוב',
    name: 'outside-event',
  },
  {
    text: 'אירוע רב נפגעים',
    additional: 'פגיעת טילים',
    name: 'bombing',
  },
]
