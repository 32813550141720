import myAxios from '@/services/myAxios'
export default {
  state: {
    statusReports: [],
  },
  getters: {
    statusReports: state => state.statusReports,
  },
  mutations: {
    //store one report

    'statusReport/set': (state, payload) => {
      state.statusReports = payload
    },
  },
  actions: {
    'statusReport/index': async context => {
      const { data } = await myAxios.get('/event/status-reports-admin')
      context.commit('statusReport/set', data)
    },
  },
}
