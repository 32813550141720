import myAxios from '@/services/myAxios'

export default {
  state: {
    isAuth: false,
    isAdmin: false,
    team: null,
    username: null,
    role: null,
    token: null,
    teams: [],
  },
  getters: {
    username: state => state.username,
    team: state => state.team,
    isAuth: state => state.isAuth,
    isAdmin: state => state.isAdmin,
    role: state => state.role,
    teams: state => state.teams,
  },
  mutations: {
    setUserName: (state, payload) => (state.username = payload),
    setTeams: (state, payload) => (state.teams = payload),
    setAdmin: (state, payload) => (state.isAdmin = payload),
    'auth/login': (state, user) => {
      state.isAuth = true
      state.team = user.team
      state.username = user.username
      state.role = user.role
    },
    'auth/loginByToken'(state, { user, token }) {
      this.token = token
      state.isAuth = true
      state.username = user.username
      state.role = user.role
      localStorage.setItem('token', token)
    },
    clearData: state => {
      state.isAuth = false
      state.isAdmin = false
      state.team = null
      state.username = null
      state.role = null
    },
  },
  actions: {
    'auth/register': async (context, payload) => {
      let { data } = await myAxios.post('/auth/register', payload)
      context.commit('auth/login', data)
    },
    'auth/login': async (context, payload) => {
      let { data } = await myAxios.post(`/auth/login`, payload)

      context.commit('auth/loginByToken', data)
    },
    'auth/checkLogin': async context => {
      try {
        let { data } = await myAxios.get('/auth/admin/checkLogin')
        context.commit('auth/login', data)
      } catch (e) {
        throw new Error()
      }
    },
    'auth/logout': async context => {
      await myAxios.post('/auth/logout')
      context.commit('clearData')
    },
    'auth/teams': async context => {
      let { data } = await myAxios.get('/auth/teams')
      context.commit('setTeams', data)
    },
  },
}
