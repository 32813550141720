<template>
  <div>
    <v-container>
      <v-card>
        <v-card-title class="d-flex align-center">
          <v-row>
            <v-col md="5" cols="12">
              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                icon
                single-line
                hide-details
              ></v-text-field>
            </v-col>
            <v-col md="6" cols="12">
              <v-select
                v-model="event"
                :items="eventType"
                menu-props="auto"
                hide-details
                item-value="name"
                :label="$t('generics.select')"
                single-line
                :item-text="({ text, additional }) => `${text} (${additional})`"
              ></v-select>
            </v-col>
            <v-col md="1" cols="12" class="center-all">
              <v-icon
                color="primary"
                @click="loadData"
                v-text="'mdi-refresh'"
              />
              <v-icon color="primary" @click="addItem" v-text="'mdi-plus'" />
            </v-col>
          </v-row>
        </v-card-title>
        <v-overlay :value="loading" color="transparent">
          <v-progress-circular
            color="primary"
            indeterminate
            size="64"
          ></v-progress-circular>
        </v-overlay>
        <v-data-table
          :headers="headers"
          :items="itemsNew"
          :search="search"
          :loading="loading"
          :items-per-page="-1"
          :sort-by.sync="sortBy"
          ref="table"
        >
          <template #body="props">
            <draggable
              v-if="$refs.table"
              tag="tbody"
              :list="itemsNew"
              :options="{ handle: '.drag-icon' }"
              @end="onEnd"
            >
              <v-nodes :vnodes="$refs.table.genItems(props.items, props)" />
            </draggable>
          </template>
          <template v-slot:item="i">
            <tr>
              <td v-if="!sortBy" class="drag-icon">
                <v-icon large>mdi-drag</v-icon>
              </td>
              <td :class="getCategoryColor(i.item.category)">
                {{ categoryName(i.item.category) }}
              </td>
              <td>{{ i.item.title }}</td>
              <td>{{ i.item.comment }}</td>
              <td>
                <v-tooltip top :content="item.link">
                  <span>{{ i.item.link }}</span>
                  <template v-slot:activator="{ on }">
                    <a
                      :href="i.item.link"
                      target="_blank"
                      rel="noopener noreferrer"
                      v-on="on"
                    >
                      {{ truncateText(i.item.link, 30) }}
                    </a>
                  </template>
                </v-tooltip>
              </td>
              <td>{{ i.item.isRecurring ? 'כן' : 'לא' }}</td>
              <td>
                {{ i.item.recurringLength
                }}{{ i.item.isRecurring ? ' דקות' : '' }}
              </td>
              <td>
                <v-icon color="primary" small @click="copyItem(i.item)"
                  >mdi-content-copy</v-icon
                >
                <v-icon color="green" small @click="editItem(i.item)"
                  >mdi-pencil</v-icon
                >
                <v-icon color="error" small @click="deleteItem(i.item)"
                  >mdi-delete</v-icon
                >
              </td>
            </tr>
          </template>
        </v-data-table>
      </v-card>
      <to-do-dialog
        v-if="openModel"
        v-model="openModel"
        :todo="item"
        :isNew="isNew"
        :eventCategory="event"
        @loadData="loadData"
      />
    </v-container>
  </div>
</template>

<script>
import draggable from 'vuedraggable'
import ToDoDialog from '../components/ToDoDialog.vue'
import { categories } from '@/helpers/categoriesAndTypes'
import { cloneDeep } from 'lodash'
export default {
  name: 'to-do-crud',

  components: {
    ToDoDialog,
    draggable,
    VNodes: {
      functional: true,
      render: (h, ctx) => ctx.props.vnodes,
    },
  },
  data() {
    return {
      loading: false,
      openModel: false,
      isNew: false,
      item: {},
      limitFields: [],
      sortBy: null,
      headers: [
        {
          text: '',
          value: 'drag',
          sortable: false,
          class: 'drag-icon',
          cellClass: 'drag-icon',
        },
        { text: this.$tc('events.totality'), value: 'category' },
        { text: this.$tc('generics.title'), value: 'title' },
        { text: this.$tc('generics.comment'), value: 'comment' },
        { text: this.$tc('generics.link'), value: 'link', width: '15%' },
        { text: this.$tc('generics.isRecurring'), value: 'isRecurring' },
        {
          text: this.$tc('generics.recurringLength'),
          value: 'recurringLength',
        },
        {
          text: this.$t('generics.actions'),
          value: 'actions',
          width: '10%',
        },
      ],
      search: '',
      event: 'fire',
      itemsNew: [],
    }
  },
  computed: {
    eventType() {
      return this.$store.getters.eventTypes
    },
    items() {
      return this.$store.getters.todos
    },
    itemCategoriesMapIndex() {
      return this.itemsNew.reduce((acc, item, index) => {
        acc[index] = item.category
        return acc
      }, {})
    },
  },
  methods: {
    async onEnd(event) {
      const category = event.item._underlying_vm_.category
      const beforeNewIndex = event.newIndex - 1
      const afterNewIndex = event.newIndex + 1
      const beforeCategory = this.itemCategoriesMapIndex[beforeNewIndex]
      const afterCategory = this.itemCategoriesMapIndex[afterNewIndex]
      if ([beforeCategory, afterCategory].every(item => item !== category)) {
        this.itemsNew = cloneDeep(this.items)
        return
      }
      const itemsToUpdateInServer = this.itemsNew.filter(
        item => category === item.category
      )
      const changedItems = itemsToUpdateInServer.reduce((acc, item, index) => {
        if (item.index !== index) {
          acc.push({ ...item, newIndex: index })
        }
        return acc
      }, [])

      if (changedItems.length) {
        this.loading = true
        await this.$store.dispatch('todo/updateIndexes', {
          changedItems,
          eventType: this.event,
        })
        this.loading = false
      }
    },
    truncateText(text, limit) {
      if (!text) return ''
      if (text.length > limit) {
        return text.slice(0, limit) + '..'
      }
      return text
    },
    eventName(eventName) {
      return this.eventType.find(c => c.name === eventName)?.text
    },
    categoryName(category) {
      return categories.find(c => c.category === category)?.text
    },
    getCategoryColor(category) {
      return categories.find(c => c.category === category)?.color
    },
    async loadData() {
      this.loading = true
      await this.$store.dispatch('todo/index', this.event)
      this.itemsNew = cloneDeep(this.items)
      this.loading = false
    },
    addItem() {
      this.isNew = true
      this.openModel = true
    },
    editItem(item) {
      this.isNew = false
      this.item = item
      this.openModel = true
    },
    async deleteItem(item) {
      let { isConfirmed } = await this.$createSwal({
        title: 'האם אתה בטוח שאתה רוצה למחוק?',
        confirmButtonText: this.$t('generics.erase'),
      })
      if (!isConfirmed) return
      this.$store.dispatch('todo/destroy', item?._id)
    },
    async copyItem(item) {
      await this.$store.dispatch('todo/replicate', item?._id)
    },
  },
  async mounted() {
    if (!this.items?.length) {
      await this.loadData()
    }
  },
  watch: {
    event() {
      this.loadData()
    },
    items() {
      this.itemsNew = cloneDeep(this.items)
    },
  },
}
</script>
