<template>
  <v-navigation-drawer app right v-model="openModal">
    <div class="d-flex justify-center">
      <h3
        class="primary--text font-weight-bold mt-3"
        v-text="$t('generics.mainMenu')"
      />
    </div>
    <v-divider class="my-2" />
    <v-list nav dense v-for="(item, i) in items" :key="i">
      <v-list-item
        @click="goRoute(item.name)"
        :disabled="$route.name === item.route"
      >
        <v-list-item-icon>
          <v-icon color="primary">{{ item.icon }}</v-icon>
        </v-list-item-icon>
        <v-list-item-title v-text="item.title" />
      </v-list-item>
    </v-list>
    <v-divider class="mt-10" />
    <v-list-item @click="logout">
      <v-list-item-icon>
        <v-icon color="red">mdi-logout</v-icon>
      </v-list-item-icon>
      <v-list-item-title class="red--text" v-text="$t('auth.logout')" />
    </v-list-item>
  </v-navigation-drawer>
</template>

<script>
export default {
  name: 'my-drawer',
  props: { value: Boolean },
  data() {
    return {
      excelDialog: false,
    }
  },
  computed: {
    items() {
      const buttonsData = [
        {
          title: 'משימות ברירת מחדל',
          route: '/to-do',
          name: 'ToDoCrud',
        },
        {
          title: 'ניהול משתמשים',
          route: '/users',
          name: 'UsersCrud',
        },
        {
          title: 'ניהול סוגי ארועים',
          route: '/event-types',
          name: 'EventTypesCrud',
        },
        {
          title: 'דו"חות סיכום',
          route: '/status-report',
          name: 'StatusReport',
        },
      ]
      // const appRoutes = this.$router.getRoutes();
      // filter unauthorized routes
      return buttonsData.filter((/*{ route: routeName }*/) => {
        // const route = appRoutes.find(({ name }) => name === routeName);
        // const routeMaxRole = route?.meta?.maxRole;
        // const userRole = this.$store.getters?.role;
        // return userRole <= routeMaxRole;
        return true
      })
    },
    openModal: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      },
    },
  },
  methods: {
    goRoute(r) {
      this.$router.push({ name: r })
      this.openModal = false
    },
    async logout() {
      await this.$store.dispatch('auth/logout', {
        toastOptions: { disabled: true },
      })
      await this.$router.push({ name: 'Login' })
    },
  },
}
</script>
