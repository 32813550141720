import myAxios from '@/services/myAxios'

export default {
  state: {
    todo: null, //chosen todo
    todos: [],
    filtered: [],
  },
  getters: {
    todos: state => state.todos,
    todo: state => state.todo,
    todoFiltered: state => state.filtered,
    filterTodosByEventType: state => category =>
      state.todos.filter(t => t.eventCategory === category),
  },
  mutations: {
    //sets all todos
    'todos/set': (state, payload) => {
      state.todos = payload
      state.filtered = [...state.todos]
    },
    //sets one todo
    'todo/set': (state, payload) => (state.todo = payload),
    //filters the todo's array by todo's key and todo's val
    'todos/filter': (state, { key, val }) => {
      state.filtered = !val
        ? [...state.todos]
        : state.todos.filter(f => f[key] === val)
    },
    //store one todo
    'todo/store': (state, payload) => state.todos.push(payload),
    //destroys one todo
    'todo/destroy': (state, id) =>
      (state.todos = state.todos.filter(item => {
        return item._id !== id
      })),
    //updates one todo
    'todo/update': (state, payload) => {
      state.todos = state.todos.map(item => {
        if (item._id === payload._id) {
          return { ...item, ...payload }
        }
        return item
      })
    },
    'todo/replicate': (state, { data, todoId }) => {
      //find the index of the todo that was replicated
      const index = state.todos.findIndex(t => t._id === todoId)
      //add the new todo to the same index
      state.todos.splice(index, 0, data)
    },
  },
  actions: {
    //fetch all todos
    'todo/index': async (context, eventType) => {
      const { data } = await myAxios.get('/templates/todo/' + eventType)
      context.commit('todos/set', data)
    },
    //fetch one todo by id
    'todo/show': async (context, id) => {
      let { data } = await myAxios.get('/templates/todo/' + id)
      context.commit('todo/set', data)
    },
    //stores one todo
    'todo/store': async (context, payload) => {
      let { data } = await myAxios.post('/templates/todo', { ...payload })
      context.commit('todos/set', data)
    },
    //destroys one todo
    'todo/destroy': async (context, id) => {
      await myAxios.delete('/templates/todo/' + id)
      context.commit('todo/destroy', id)
    },
    //updates one todo by its id
    'todo/update': async (context, payload) => {
      await myAxios.put('/templates/todo/' + payload._id, payload)
      context.commit('todo/update', payload)
    },
    'todo/updateIndexes': async (context, { eventType, changedItems }) => {
      const { data } = await myAxios.put(
        '/templates/todo/update-indexes/' + eventType,
        changedItems
      )
      context.commit('todos/set', data)
    },
    'todo/replicate': async (context, todoId) => {
      const { data } = await myAxios.post('/templates/todo/replicate/' + todoId)
      context.commit('todo/replicate', { data, todoId })
    },
  },
}
