import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from '../views/Login.vue'
import ToDoCrud from '../views/ToDoCrud.vue'
import StatusReport from '../views/StatusReport.vue'
import UsersCrud from '../views/UsersCrud.vue'
import store from '@/store'

Vue.use(VueRouter)

const LoginGuard = (to, from, next) => {
  if (!store.getters.isAuth) {
    return to.name === 'Login' ? next() : next({ name: 'Login' })
  }
  return next({ name: 'ToDoCrud' })
}

const routes = [
  {
    path: '/status-report',
    name: 'StatusReport',
    component: StatusReport,
  },
  {
    path: '/users',
    name: 'UsersCrud',
    component: UsersCrud,
  },
  {
    path: '/',
    name: 'Login',
    component: Login,
    beforeEnter: LoginGuard,
  },
  {
    path: '/to-do',
    name: 'ToDoCrud',
    component: ToDoCrud,
  },

  {
    path: '/event-types',
    name: 'EventTypesCrud',
    component: () => import('../views/EventTypesCrud.vue'),
  },

  {
    path: '/*',
    name: 'NotFound',
    redirect: '/',
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
})
router.beforeResolve((to, from, next) => {
  const isAuth = store.getters.isAuth

  if (!isAuth) {
    return to.name === 'Login' ? next() : next({ name: 'Login' })
  }

  return next()
})
export default router
