import myAxios from '@/services/myAxios'
export default {
  state: {
    types: [],
  },
  getters: {
    eventTypes: state => state.types,
  },
  mutations: {
    'eventType/set': (state, payload) => {
      state.types = payload
    },
    'eventType/add': (state, payload) => {
      state.types.push(payload)
    },
    'eventType/update': (state, payload) => {
      const index = state.types.findIndex(type => type._id === payload._id)
      state.types.splice(index, 1, payload)
    },
    'eventType/delete': (state, payload) => {
      const index = state.types.findIndex(type => type.id === payload)
      state.types.splice(index, 1)
    },
  },
  actions: {
    'eventType/index': async context => {
      const { data } = await myAxios.get('/event-type')
      context.commit('eventType/set', data)
    },
    'eventType/store': async (context, payload) => {
      const { data } = await myAxios.post('/event-type', payload)
      context.commit('eventType/add', data.id)
    },
    'eventType/update': async (context, payload) => {
      const { data } = await myAxios.put(`/event-type/${payload._id}`, payload)
      context.commit('eventType/update', data)
    },
    'eventType/destroy': async (context, payload) => {
      await myAxios.delete(`/event-type/${payload}`)
      context.commit('eventType/delete', payload)
    },
  },
}
