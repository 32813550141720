<template>
  <!-- TODO: add title -->
  <my-dialog v-model="model" :title="$t()">
    <template v-slot:content>
      <div>
        <v-select
          v-model="form.eventCategory"
          :items="eventTypes"
          class="mb-10"
          menu-props="auto"
          hide-details
          item-text="text"
          item-value="value"
          single-line
          outlined
        />
        <v-select
          v-model="form.category"
          :items="categories"
          class="mb-10"
          menu-props="auto"
          hide-details
          item-text="text"
          item-value="category"
          label="בחר מכלול"
          single-line
          outlined
        />
        <v-text-field v-model="form.title" label="תוכן המשימה" solo outlined />
        <v-textarea v-model="form.comment" solo label="רשום הערה" />
        <v-text-field
          v-if="showAddLink"
          v-model="form.link"
          solo
          label="הוסף קישור כללי"
        />
        <v-row v-if="showAddLink" class="my-4 mx-1 align-center">
          <span>הוסף קישור יחודי לישוב</span>
          <v-btn icon class="ms-2" @click="addDifferentialLink">
            <v-icon>mdi-plus</v-icon></v-btn
          >
        </v-row>
        <v-row v-for="(link, index) in form.differentialLinks" :key="index">
          <v-col cols="3">
            <v-select
              v-model="form.differentialLinks[index].team"
              solo
              label="בחר ישוב"
              :items="teams"
              :item-text="team => team.name"
              :item-value="team => team._id"
            ></v-select
          ></v-col>
          <v-col cols="8" class="mr-n5">
            <v-text-field
              v-model="form.differentialLinks[index].link"
              solo
              label="קישור יחודי"
          /></v-col>
          <v-col cols="1">
            <v-btn icon class="ms-2" @click="deleteDifferentialLink(index)">
              <v-icon>mdi-close</v-icon></v-btn
            >
          </v-col>
        </v-row>
        <v-btn
          v-if="!showAddLink"
          @click="showAddLink = true"
          color="secondaryLight font-weight-bold mb-5"
        >
          <span class="ml-2">הוספת קישור</span>
          <v-icon color="orange">mdi-link-variant</v-icon>
        </v-btn>
        <v-btn
          v-else
          @click="showAddLink = false"
          color="secondaryLight font-weight-bold mb-5"
        >
          <span class="ml-2">הסר קישור</span>
          <v-icon color="orange">mdi-link-variant</v-icon>
        </v-btn>
        <v-row class="align-center" dense>
          <v-col cols="" class="d-flex align-center px-2">
            <v-switch
              v-model="form.isRecurring"
              color="orange"
              class="mt-1 px-2"
              inset
              hide-details
            />
            <span class="d-inline-block px-2"
              >{{ form.isRecurring ? 'חזרה כל' : 'מחזורי?' }}
            </span>
            <div class="d-flex align-center px-2" v-if="form.isRecurring">
              <vue-numeric-input
                v-model="form.recurringLength"
                :disabled="!form.isRecurring"
                :max="1440"
                :min="1"
                :step="1"
                style="background-color: white"
                width="80px"
                controlsType="updown"
                align="center"
              />
              <span class="d-inline-block px-2">דקות</span>
            </div>
          </v-col>
        </v-row>
      </div>
    </template>
    <template v-slot:actions>
      <v-btn @click="submit" color="green" class="white--text">
        {{ $t('generics.save') }}</v-btn
      >
    </template>
  </my-dialog>
</template>

<script>
import MyDialog from '@/components/generics/MyDialog.vue'
import { categories } from '@/helpers/categoriesAndTypes'

export default {
  name: 'add-todo',
  components: {
    MyDialog,
  },
  props: {
    value: { type: Boolean, default: false },
    isNew: { type: Boolean, default: false },
    eventCategory: { type: String },
    todo: { type: Object, default: () => ({}) },
  },
  data() {
    return {
      showAddLink: false,
      form: {
        isRecurring: false,
        recurringLength: null,
        title: null,
        comment: null,
        link: null,
        category: null,
        differentialLinks: [],
      },
      categories: categories,
    }
  },
  methods: {
    addDifferentialLink() {
      this.form.differentialLinks.push({ link: '', team: null })
    },
    deleteDifferentialLink(index) {
      this.form.differentialLinks.splice(index, 1)
    },
    validator() {
      return (
        !this.form.title ||
        !this.form.category ||
        this.form.differentialLinks.some(link => !link.team || !link.link)
      )
    },
    async submit() {
      if (this.validator()) return
      try {
        const actionType = this.isNew ? 'todo/store' : 'todo/update'
        await this.$store.dispatch(actionType, {
          ...this.form,
        })
        this.$emit('loadData')
        this.model = false
        return
      } catch (e) {
        console.log(e)
      }
    },
  },
  computed: {
    eventTypes() {
      return this.$store.getters['eventTypes'].map(type => ({
        text: `${type.text} - (${type.additional})`,
        value: type.name,
      }))
    },
    model: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      },
    },
    teams() {
      return this.$store.getters.teams
    },
  },
  mounted() {
    if (!this.teams.length)
      this.$store.dispatch('auth/teams', { toastOptions: { disabled: true } })
    if (!this.isNew) {
      this.form = { ...this.form, ...this.todo }
      if (this.form.link) this.showAddLink = true
    }
  },
  watch: {
    showAddLink() {
      if (!this.showAddLink) {
        this.form.link = null
      }
    },
    'form.isRecurring'() {
      if (!this.form.isRecurring) {
        this.form.recurringLength = null
      }
    },
  },
}
</script>
