import Vue from 'vue'
import Vuex from 'vuex'
import authModule from './modules/authModule'
import todoModule from './modules/todoModule'
import Notifications from '@/services/notifications'
import I18n from '@/i18n'
import teamModule from './modules/teamModule'
import eventModule from './modules/eventModule'
import eventTypeModule from './modules/eventTypeModule'

Vue.use(Vuex)

const modules = {
  auth: authModule,
  todo: todoModule,
  team: teamModule,
  event: eventModule,
  eventType: eventTypeModule,
}

let vuexData = {
  state: {
    title: 'zahi',
    backgroundImage: '/images/Artboard_2@2x.png',
  },
  getters: {
    title: state => state.title,
    backgroundImage: state => state.backgroundImage,
  },
  mutations: {
    setBackgroundImage: (state, payload) =>
      (state.backgroundImage = `/images/${payload}.png`),
  },
  modules,
}

const store = new Vuex.Store(vuexData)

store.subscribeAction({
  error: (action, state, error) => {
    if (action.payload?.toastOptions?.disabled) return
    Notifications.errorToast('', extractClapError(error))
  },
  after: action => {
    const { disabled, title, message } = action.payload?.toastOptions || {}
    const actionSuffix = action.type.match(/\/[^/]+?$/)?.[0].replace('/', '')
    if (disabled || actionSuffix === 'index') return
    const toastMessageType =
      actionSuffix === 'update'
        ? 'update'
        : actionSuffix === 'store'
        ? 'store'
        : actionSuffix === 'destroy'
        ? 'destroy'
        : 'success'

    Notifications.successToast(
      title || '',
      message || I18n.t(`toasts.${toastMessageType}`)
    )
  },
})

function extractClapError(e) {
  let lang = I18n._vm.locale || 'he'
  if (e && e.response && e.response.data && e.response.data.clapErr) {
    let errObj = e.response.data
    return errObj[lang]
  } else {
    return 'משהו השתבש'
  }
}

export default store
