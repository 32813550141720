<template>
	<my-dialog :max-width="500" v-model="model" :title="$t()">
		<template v-slot:content>
			<v-text-field
				v-model="form.name"
				:label="$t('userManagement.name')"
			></v-text-field>
			<v-text-field
				v-model="form.password"
				:label="$t('auth.password')"
			></v-text-field>
		</template>
		<template v-slot:actions>
			<v-btn
				@click="submit"
				:disabled="!validForm"
				color="green"
				class="white--text"
			>
				{{ $t('generics.save') }}</v-btn
			>
		</template>
	</my-dialog>
</template>

<script>
	import MyDialog from '@/components/generics/MyDialog.vue';

	export default {
		name: 'user-dialog',
		components: {
			MyDialog,
		},
		props: {
			value: { type: Boolean, default: false },
			userProp: { type: Object, default: () => ({}) },
		},
		data() {
			return {
				form: {
					name: '',
					password: '',
				},
			};
		},
		methods: {
			submit() {
				this.$store.dispatch(
					`team/${this.isNew ? 'store' : 'update'}`,
					this.form
				);
				this.model = false;
			},
		},
		computed: {
			validForm() {
				if (this.isNew) return this.form.name && this.form.password;
				return this.form.name;
			},
			isNew() {
				return !this.form._id;
			},
			model: {
				get() {
					return this.value;
				},
				set(value) {
					this.$emit('input', value);
				},
			},
		},
		mounted() {
			if (this.userProp._id) {
				this.form = { ...this.userProp, password: null };
			}
		},
		watch: {},
	};
</script>
